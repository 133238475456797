import { FC, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ButtonsContainer, LoadingButton, Modal, nanoid } from '@forma/forma-ui-kit';
import FillDocument from 'views/FillDocument';
import copyToClipboard from 'helpers/copyToClipboard';

import { IRendersItem, TFormatDocument } from 'interfaces/templates.interface';
import {
  useRenderTemplateMutation,
  useLazyDownloadRenderedTemplateQuery,
  ITemplateRenderData,
  useRenderHtmlMutation
} from 'store/templates/templatesApi';
import { addNotification } from 'store/notifications/notificationsSlice';

import styles from './ModalChangeDocument.module.css';
import { TRIAL_ID } from 'data/constants';
import TrialEndModal from 'views/Guide/TrialEndModal';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import webView from 'helpers/webview';

interface ModalChangeDocumentProps {
  open: boolean,
  onClose: () => void,
  selectedTemplate: IRendersItem
}

const ModalChangeDocument: FC<ModalChangeDocumentProps> = ({ open, onClose, selectedTemplate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ searchParams ] = useSearchParams();
  const debug = searchParams.get('debug');

  const { data: currentTariff } = useGetCurrentTariffQuery();
  const [ rerenderTemplate, { isLoading: isRerenderDownloading } ] = useLazyDownloadRenderedTemplateQuery();
  const [ renderTemplate, { isLoading: isDownloadLoading } ] = useRenderTemplateMutation();
  const [ renderHtml ] = useRenderHtmlMutation();

  const [ isFillingValid, setIsFillingValid ] = useState<boolean>(false);
  const [ sidesData, setSidesData ] = useState<ITemplateRenderData['sideValues']>([]);;
  const [ isChangedData, setChangedData ] = useState<boolean>(false);
  const [ isOpenTrial, setOpenTrial ] = useState<boolean>(false);

  useEffect(() => {
    if (open) window?.ReactNativeWebView?.postMessage('documentIsOpen');
    else window?.ReactNativeWebView?.postMessage('documentClosed');
  }, [open]);

  const handleClickRender = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
  ) => {
    renderHtml({ id: selectedTemplate.template.id, name, sideValues }).then(result => {
      if ('data' in result) {
        copyToClipboard(result.data);
        dispatch(addNotification({ content: t('copied'), type: 'SUCCESS' }));
      }
    }).catch(e => {});
  };

  const handleClickDownload = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument = 'pdf',
    isChanged: boolean
  ) => {
    if (!isChanged) {
      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.renderDownload(selectedTemplate.template.id, selectedTemplate.id, `${name}.${format}`, format);
      } else {
        rerenderTemplate({ id: selectedTemplate.template.id, rerenderId: selectedTemplate.id, format }).unwrap().then(url => {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${name}.${format}`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          onClose();
        }).catch(e => {});
      }
    } else {
      if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
        setOpenTrial(true);
        return;
      }

      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.renderTemplate(selectedTemplate.template.id, `${name}.${format}`, { name, format, sideValues });
      } else {
        const data: ITemplateRenderData = {
          id: selectedTemplate.template.id,
          name,
          format,
          sideValues
        };

        renderTemplate(data).unwrap().then(url => {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${name}.${format}`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          onClose();
        }).catch(e => {});
      }
    }
  };

  const sides = useMemo(() => selectedTemplate?.sides.map((data, index) => ({ ...data, id: data.id ?? index+1+'' })), [selectedTemplate]);
  const valueSides = useMemo(() => selectedTemplate.sideValues?.reduce((acc, data, index) => ({
    ...acc,
    [sides[index].id]: {
      ...data,
      id: sides[index].id,
      tableValues: data.tableValues?.length > 0 ? (
        data.tableValues.map(({ products, ...table }) => ({
          ...table,
          products: products.reduce((acc: { [id: string]: { [key: string]: string } }, current) => ({
            ...acc,
            [nanoid(8)]: current
          }), {})
        }))
      ) : [],
      // isValid: true,
      tattrErrors: {}
    }
  }), {}), [selectedTemplate, sides]);

  return <Modal
    open={open}
    onClose={onClose}
    title={selectedTemplate.name}
    width="100%"
    maxWidth="1150px"
  >
    <div className={styles.root}>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          type="submit"
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          onClick={() => handleClickDownload(selectedTemplate.name, sidesData, 'pdf', isChangedData)}
          disabled={!isFillingValid}
          isLoading={isRerenderDownloading}
          icon={<ReactSVG src="/icons/download.svg" />}
        >
          {t('download_pdf')}
        </LoadingButton>
        <LoadingButton
          type="submit"
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          onClick={() => handleClickDownload(selectedTemplate.name, sidesData, 'docx', isChangedData)}
          disabled={!isFillingValid}
          isLoading={isRerenderDownloading}
          icon={<ReactSVG src="/icons/download.svg" />}
        >
          {t('download_docx')}
        </LoadingButton>
      </ButtonsContainer>
      <FillDocument
        className={styles.filling}
        name={selectedTemplate?.name}
        sides={sides}
        onClickDownload={handleClickDownload}
        onClickRender={debug ? handleClickRender : undefined}
        isDownloadLoading={isDownloadLoading || isRerenderDownloading}
        valueSides={valueSides}
        setIsFillingValid={setIsFillingValid}
        setSidesDataForInnerRenderButton={setSidesData}
        onSetChanged={() => setChangedData(true)}
        showButtons={false}
        showHead={false}
      />
      <TrialEndModal
        open={isOpenTrial}
        onClose={setOpenTrial}
      />
    </div>
  </Modal>;
};

export default ModalChangeDocument;