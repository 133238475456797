import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, LoadingButton, Modal } from '@forma/forma-ui-kit';
import FillDocument from 'views/FillDocument';
import SendExternalFillModal from 'views/ExternalFill/SendExternalFillModal';
import webView from 'helpers/webview';

import { ITemplateRenderData, useGetTemplateSidesQuery, useRenderTemplateMutation } from 'store/templates/templatesApi';
import { useDuplicateExternalFillMutation, useLazyDownloadExternalFillAttachmentsQuery, useSendExternalFillMutation } from 'store/externalfill/externalfillApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { IExternalFillItem } from 'interfaces/externalfill.interface';
import { TFormatDocument } from 'interfaces/templates.interface';

import styles from './ExternalFillModal.module.css';


interface ExternalFillModalProps {
  open: boolean,
  onClose?: () => void,
  data: IExternalFillItem
}

const InfoItem: FC<{ label: string, value?: string }> = ({ label, value }) => {
  return (
    <div className={styles.infoItem}>
      <div className={styles.infoLabel}>{label}:</div>
      <div className={styles.infoValue}>{value}</div>
    </div>
  );
};

const ExternalFillModal: FC<ExternalFillModalProps> = ({
  open, onClose, data
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, name, createdAt, readAt, filledAt, templateId, sideValues, attachments, isReady, urlKey } = data;
  const { data: templateSides } = useGetTemplateSidesQuery(templateId, { skip: !templateId || !open });
  const [ duplicateExternalFill ] = useDuplicateExternalFillMutation();
  const [ downloadExternalFillAttachments, { isLoading: isAttachmentsLoading } ] = useLazyDownloadExternalFillAttachmentsQuery();
  const [ renderTemplate, { isLoading: isDownloading } ] = useRenderTemplateMutation();
  const [ sendExternalFill ] = useSendExternalFillMutation();

  const [ isShowSend, setShowSend ] = useState<boolean>(false);
  const [ isFillingValid, setIsFillingValid ] = useState<boolean>(false);
  const [ sidesData, setSidesData ] = useState<ITemplateRenderData['sideValues']>(sideValues);

  useEffect(() => {
    if (open) window?.ReactNativeWebView?.postMessage('documentIsOpen');
    else window?.ReactNativeWebView?.postMessage('documentClosed');
  }, [open]);

  const handleClickRender = (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument
  ) => {
    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.renderTemplate(templateId, `${name}.${format}`, { name, sideValues, format });
    } else {
      renderTemplate({ id: templateId, name, sideValues, format: format }).unwrap().then(url => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}.${format}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(e => {});
    }
  };

  const handleClickDuplicate = async () => {
    const res = await duplicateExternalFill({ id });
    if ('data' in res) {
      dispatch(addNotification({ content: t('external_fill_created'), type: 'SUCCESS' }));
      localStorage.setItem('externalFillSendingId', res.data.id);
      navigate('/documents');
      if (onClose) onClose();
    }
  };

  const handleClickDownload = () => {
    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.externalFillAttachemnts(id, `${name}.zip`);
      if (onClose) onClose();
    } else {
      downloadExternalFillAttachments(id).unwrap().then(url => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        if (onClose) onClose();
      }).catch(e => {});
    }
  };

  const handleClickSend = async (id: string, data: { login: string }) => {
    setShowSend(false);
    const res = await sendExternalFill({ id, email: data.login });
    if ('data' in res) {
      dispatch(addNotification({ content: t('email_sended_to_your_counteragent'), type: 'SUCCESS' }));
    }
  };

  const sides = useMemo(() => templateSides?.sides.map((data, index) => ({ ...data, id: data?.id ?? index+1+'' })), [templateSides]);
  const valueSides = useMemo(() => templateSides && sideValues?.reduce((acc, data, index) => ({
    ...acc,
    [templateSides.sides[index]?.id ?? index+1+'']: {
      ...data,
      id: templateSides.sides[index]?.id ?? index+1+'',
      // tableValues: data.tableValues?.length > 0 ? data.tableValues : null,
      // isValid: true,
      tattrErrors: {}
    }
  }), {}), [sideValues, templateSides]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={name}
      width="100%"
      maxWidth="1150px"
    >
      <div className={styles.root}>
        <div className={styles.info}>
          <InfoItem label={t('created')} value={format(new Date(createdAt), 'dd.MM.yyyy HH:mm')} />
          <InfoItem label={t('opened')} value={readAt && format(new Date(readAt), 'dd.MM.yyyy HH:mm')} />
          <InfoItem label={t('filled_and_sended')} value={filledAt && format(new Date(filledAt), 'dd.MM.yyyy HH:mm')} />
        </div>
        <ButtonsContainer className={styles.buttons}>
          <LoadingButton
            type="submit"
            className={styles.button}
            viewStyle="tertiary"
            size="small"
            onClick={() => handleClickRender(name, sidesData, 'pdf')}
            disabled={!isFillingValid}
            isLoading={isDownloading}
            icon={<ReactSVG src="/icons/download.svg" />}
          >
            {t('download_pdf')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            className={styles.button}
            viewStyle="tertiary"
            size="small"
            onClick={() => handleClickRender(name, sidesData, 'docx')}
            disabled={!isFillingValid}
            isLoading={isDownloading}
            icon={<ReactSVG src="/icons/download.svg" />}
          >
            {t('download_docx')}
          </LoadingButton>
          <LoadingButton
            viewStyle="tertiary"
            size="small"
            className={styles.button}
            icon={<ReactSVG src="/icons/download-file.svg" />}
            onClick={handleClickDownload}
            disabled={!attachments.length || !attachments.every(({ size }) => !!size)}
            isLoading={isAttachmentsLoading}
          >
            {t('download_attachments')}
          </LoadingButton>
          <LoadingButton
            viewStyle="tertiary"
            size="small"
            className={styles.button}
            icon={<ReactSVG src="/icons/copy.svg" />}
            onClick={handleClickDuplicate}
            disabled={!!isReady}
          >
            {t('duplicate_to_filling')}
          </LoadingButton>
          <LoadingButton
            viewStyle="primary"
            size="small"
            className={styles.button}
            onClick={() => setShowSend(true)}
            disabled={!!isReady}
          >
            {t('send_to_fiiling')}
          </LoadingButton>
        </ButtonsContainer>

        {sides && (
          <FillDocument
            className={styles.filling}
            name={name}
            sides={sides}
            onClickDownload={handleClickRender}
            isDownloadLoading={isDownloading}
            valueSides={valueSides}
            showFillMessage={false}
            setIsFillingValid={setIsFillingValid}
            setSidesDataForInnerRenderButton={setSidesData}
            showButtons={false}
            showHead={false}
          />
        )}
      </div>

      <SendExternalFillModal
        open={isShowSend}
        onClose={() => setShowSend(false)}
        onSubmit={data => handleClickSend(id, data)}
        urlKey={urlKey}
      />
    </Modal>
  );
};

export default ExternalFillModal;
