import { useEffect, lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import { registerLocale } from 'react-datepicker';
import { ru } from 'date-fns/locale';
import NotificationBar from 'components/NotificationBar';
import { selectToken } from 'store/auth/authSlice';
import { removeNotification, selectNotifications } from 'store/notifications/notificationsSlice';
import { selectMenuOpen, selectOnboardingModal } from 'store/common/commonSlice';
import { ThemeContext, Notifications, useScrollToTop, useViewport } from '@forma/forma-ui-kit';
import ProtectedRoute from 'pages/ProtectedRoute';
import MainQuery from 'pages/MainQuery';

import { setUserLang, selectUserLang, selectUserData, selectOnboarding } from 'store/user/userSlice';
import { schemasApi } from 'store/schemas/schemasApi';

import routes from 'data/routes';
import { currencies } from 'data/mock';
import DiscountModal from 'views/Guide/DiscountModal';
import { setCookie } from 'helpers/cookies';
import { addDays } from 'date-fns';

const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
const apiUrl = process.env.REACT_APP_API_URL;

const isProduction = process.env.NODE_ENV === 'production';

const FillProfileModal = lazy(() => import('views/profile/FillProfileModal'));
const AddTemplatesModal = lazy(() => import('views/profile/AddTemplatesModal'));
const Listing = lazy(() => import('views/onboards/Listing'));

registerLocale('ru', ru);

const RouteComponent = ({ isLogged, children }: { isLogged: boolean, children: JSX.Element }) => {
  const navigate = useNavigate();
  const { pathname, hash, key } = useLocation();
  const [ searchParams ] = useSearchParams();
  const roistatVisit = searchParams.get('roistat_visit');

  useScrollToTop(pathname, hash, key);

  useEffect(() => {
    if (roistatVisit) setCookie('roistat_visit', roistatVisit, { expires: addDays(new Date(), 14) });
  }, [roistatVisit]);

  useEffect(() => {
    const isNewRegister = localStorage.getItem('newRegister');
    if (isLogged && isNewRegister) navigate('/register/password');
    // eslint-disable-next-line
  }, [isLogged]);

  return children;
};

const App = () => {
  const dispatch = useDispatch();
  const viewport = useViewport();
  const token = useSelector(selectToken);
  const user = useSelector(selectUserData);
  const isLogged = !!useSelector(selectToken);
  const notifications = useSelector(selectNotifications);
  const language = useSelector(selectUserLang);
  // const isShowWelcome = useSelector(selectShowWelcome);
  const isMenuOpen = useSelector(selectMenuOpen);
  const onboarding = useSelector(selectOnboarding);
  const onboardingModal = useSelector(selectOnboardingModal);

  const { i18n } = useTranslation();

  const [ showAddTemplates, setShowAddTemplates ] = useState(false);

  const theme = {
    viewport,
    isMenuOpen,
    lang: i18n.resolvedLanguage ?? i18n.language,
    apiUrl: apiUrl ?? '',
    token,
    currencies
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      routes.map(props =>
        <Route
          key={props.path}
          {...props}
          element={
            <ProtectedRoute
              isAllowed={(props.logged && isLogged) || (!props.logged && !isLogged) || props.skip_auth || false}
              redirectPath={props.redirect}
            >
              <RouteComponent isLogged={isLogged}>
                <>
                  {(isLogged && user?.name && !showAddTemplates && !['phone', 'tabletS', 'tablet'].includes(viewport)) &&
                    <Suspense><Listing onboarding={onboarding} modal={onboardingModal} /></Suspense>
                  }
                  {(isLogged && user && !user.name) &&
                    <Suspense><FillProfileModal onSaveComplete={() => setShowAddTemplates(true)} /></Suspense>
                  }
                  {(isLogged && showAddTemplates) && <Suspense>
                    <AddTemplatesModal showAddTemplatesModal={showAddTemplates} onClose={() => setShowAddTemplates(false)} />
                  </Suspense>}
                  {(isLogged && user?.name) && <DiscountModal />}
                  {props.element}
                </>
              </RouteComponent>
            </ProtectedRoute>
          }
        />
      )
    )
  );

  useEffect(() => {
    const currentLanguage = i18n.resolvedLanguage ? i18n.resolvedLanguage : i18n.language;
    if (currentLanguage !== language) {
      dispatch(setUserLang(currentLanguage));
      dispatch(schemasApi.util.resetApiState());
    }
    const html = document.querySelector('html');
    if (html) html.lang = currentLanguage;
    // eslint-disable-next-line
  }, [i18n.language]);

  const handleBackButton = (e: MessageEvent) => {
    if (e.data === 'backButtonPress') {
      const popups = document.getElementById('popup-root');
      if (popups?.childElementCount) document.dispatchEvent(new KeyboardEvent('keyup', { 'key': 'Escape' }));
      else window.history.back();
    }
  };

  useEffect(() => {
    document.addEventListener('message', handleBackButton);
    return () => document.removeEventListener('message', handleBackButton);
    // eslint-disable-next-line
  }, []);

  return (
    <CacheBuster
      currentVersion={REACT_APP_VERSION}
      isEnabled={isProduction}
      isVerboseMode
    >
      <ThemeContext.Provider value={theme}>
        <MainQuery isLogged={isLogged} />
        <NotificationBar />
        <Notifications items={notifications} onRemove={id => dispatch(removeNotification(id))} />
        <RouterProvider router={router} />
      </ThemeContext.Provider>
    </CacheBuster>
  );
};

export default App;
